<template>
  <div class="text-grid-column" :style="'min-width:' + width + ';'">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.text-grid-column {
}
</style>
